<script setup>
import { ref, reactive } from 'vue'
import api from '@helpers/api'
import Icon from '@components/Icon.vue'
import Spinner from '@components/Spinner.vue'
import { generateRecaptchaToken } from '@helpers/utils'

const errorMessage = ref('')
const submitted = ref(false)
const submitting = ref(false)
const form = reactive({
  list_id: 'WQh9NS',
  email: '',
  captcha: '',
})

async function subscribe() {
  submitted.value = false
  submitting.value = true

  form.captcha = await generateRecaptchaToken('subscribe')

  api
      .post(`/subscribe`, form)
      .then(({ data }) => data)
      .then((data) => {
        submitted.value = true
        form.email = ''

        window.dataLayer.push({
          event: 'email_sign_up',
          emailSignUpLocation: 'footer',
        })
      })
      .catch((error) => {
        errorMessage.value = error?.response?.data?.message || 'An unexpected error occurred.'
      })
      .finally(() => {
        submitting.value = false
      })
}
</script>

<template>
  <div class="newsletter">
    <p class="newsletter__text">Subscribe for $10 off your next order and receive exclusive promos, product announcements and good reads.</p>
    <form @submit.prevent="subscribe">
      <div v-if="errorMessage" class="alert alert--error">
        {{ errorMessage }}
      </div>

      <div v-if="submitted" class="alert alert--success">
        You have been subscribed.
      </div>
      <div v-else class="newsletter__form">
        <input
            v-model="form.email"
            type="email"
            placeholder="Email address"
            class="newsletter__input"
        />
        <button type="submit" class="newsletter__button" aria-label="Subscribe">
          <Spinner color="white" v-if="submitting" />
          <Icon v-else type="envelope" />
        </button>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.newsletter {
  margin-bottom: 32px;

  @media screen and (min-width: 768px) {
    margin-bottom: 48px;
  }

  .newsletter__text {
    color: var(--shades-white, #FFF);
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 16px 0;

    @media screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 32px;
    }
  }

  .newsletter__form {
    display: flex;
    align-items: stretch;
    width: 100%;

    .newsletter__input {
      background: var(--shades-white, #FFF);
      color: var(--neutral-700, #767370);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      padding: 12px 8px;
      flex-grow: 1;
      border: 0;
      border-radius: 2px 0 0 2px;

      @media screen and (min-width: 768px) {
        font-size: 18px;
        line-height: 32px;
        border-radius: 4px 0 0 4px;
        padding: 16px;
      }
    }

    .newsletter__button {
      background-color: #183D4F;
      padding: 0 16px;
      border: 1px solid var(--shades-white, #FFF);
      border-radius: 0 2px 2px 0;
      cursor: pointer;

      @media screen and (min-width: 768px) {
        border-radius: 0 4px 4px 0;
      }

      svg {
        height: 20px;
        width: 20px;

        @media screen and (min-width: 768px) {
          height: 30px;
          width: 30px;
        }
      }
    }
  }
}
</style>
